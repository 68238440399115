<template>
  <div>
    <h1>A vous de jouer !</h1>
    <iframe
      src="static/frames/jouez/index.html"
      width="910"
      height="530"
      frameborder="0"
      scrolling="no"
    />
  </div>
</template>

<script>
export default {};
</script>
